<script setup>

const { serviceConfig } = useAuth()

const openSidebar = ref()
const config = useRuntimeConfig()

</script>
<template>
    <v-app>
        <CoreSideBar v-model="openSidebar" />
        <CoreNavBar @toggleSidebar="openSidebar = !openSidebar" />
        <div style="z-index: 10000; position: absolute; right: 7px; top: 70px;" class="mb-2" >
            <v-alert v-for="msg in serviceConfig.maintenance" closable variant="flat" border='start' elevation="2" class='mb-2'  min-width="800px" max-width="1200px" type='warning' :title="msg.title"><span v-html="msg.message" /></v-alert>
        </div>
        <v-main class="bg-blue-grey-lighten-5" style="height:100vh;overflow:auto">
            <v-container fluid class="pa-3" style="height:100%">
                <slot />
            </v-container>
        </v-main>
        <CoreDialog />
        <CoreNotification />
        <v-footer app height="40"><v-spacer />v.{{config.public.version}} ©  {{ (new Date()).getFullYear() }} Trialsh, made with ❤️ for a better world</v-footer>
    </v-app>
</template>

